/**
 * Cedar Public API
 * Client specification for backend Cedar application services used by the public (customer) web application.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TopItemData { 
    mom: number;
    yoy: number;
    ytd: number;
}

